<template>
  <vx-card actionable class="cardx" title="Remove SPAM Numbers">
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
      ></vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <vs-input
          label-placeholder="MOBILE NO"
          v-model="mobile"
          v-on:keypress="isNumber(event)"
        />
 
        <vs-button
          color="dark"
          type="gradient"
           ref="search" 
          icon="search"
          @click="search()"
          style="margin-left: 30px;
    margin-top: 17px;"

        ></vs-button>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
      ></vs-col>
    </vs-row>
    <div>
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        ></vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vx-card
            actionable
            class="cardx"
            v-show="mobileData"
            style="margin-top:40px; margin-left: -23px"
          >
            <vs-row style="margin:5px">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 17px;"
              >
                <b>Added By:</b>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size:17px;"
                >{{ AddedBy }}</vs-col
              >
            </vs-row>
            <vs-row style="margin:5px">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 17px;"
              >
                <b>Added On:</b>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size:17px;"
                >{{ AddedOn }}</vs-col
              >
            </vs-row>
            <vs-row style="margin:5px">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 17px;"
              >
                <b>Contact Type:</b>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size:17px;"
                >{{ ContactType }}</vs-col
              >
            </vs-row>
            <vs-row style="margin-top: 25px;">
              <vs-button
                @click="removeFromSpam"
                size="medium"
                color="danger"
                style="float:right; left:35%"
                >Remove</vs-button
              >
            </vs-row>
          </vx-card>
          <vx-card
            actionable
            class="cardx"
            v-show="errorData"
            style="margin-top:40px; margin-left: -23px"
          >
            <vs-row style="margin:5px">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="4"
              ></vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="4"
                style="font-size: 17px;"
              >
                <b style="background: red;color: white;border-radius: 7px;"
                  >Error</b
                >
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="4"
              ></vs-col>
            </vs-row>
            <vs-row style="margin-top:25px">
              <!-- <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 17px;"
              >
                <b>Message:</b>
              </vs-col>-->
              <vs-col
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
                style="font-size:17px;text-align: center;"
                >{{ MessageData }}</vs-col
              >
            </vs-row>
          </vx-card>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        ></vs-col>
      </vs-row>
    </div>
  </vx-card>
</template>

<script>
import LoadingButton from "../components/utilities/LoadingButton";
import axios from "axios";
import constants from "../../constants.json";
// import EventBus from "../components/eventbus.js";
export default {
  props: ["all_loading"],
  components: {
    LoadingButton
  },
  data() {
    return {
      mobile: "",
      AddedBy: "",
      AddedOn: "",
      ContactType: "",
      mobileData: false,
      errorData: false,
      Errordata: "",
      MessageData: "",
      event: ""
    };
  },

  mounted() {},
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    search() {
      if (!this.validateNumber()) {
        return;
      }
      let obj = this.mobile;
      this.$vs.loading();

      let url = `${constants.SERVER_API}getSpamOrVendor?phone_number=${obj}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          if (response.data.status === "success") {
            this.mobileData = true;
            this.errorData = false;
            this.AddedBy = response.data.added_by;
            this.AddedOn = response.data.added_on;
            this.ContactType = response.data.contact_type;

            // this.mobile = "";
          } else if (response.data.status === "error") {
            this.mobileData = false;
            this.errorData = true;
            this.Errordata = response.data.status;
            this.MessageData = response.data.message;
            // this.mobile = "";
          }
          this.$vs.loading.close()

          console.log("astdftyas0", response);
        })
        .catch(error => {
          this.handleError(error);
          this.$vs.loading.close()

        });
    },
    // 8074110078
    removeFromSpam() {
      let url = `${constants.SERVER_API}removeFromSpamVendor`;
      let obj = {
        phone_number: this.mobile
      };
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          if (response.data.status === "success") {
            this.mobileData = false;
            this.errorData = false;
          } else if (response.data.status === "error") {
            this.mobileData = false;
            this.errorData = false;
          }
          this.handleNotification(response);
          this.mobile = "";
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    validateNumber() {
      let number = this.mobile.toString();
      if (number.length == 10) {
        number = number.substr(number.length - 8 || number.length - 11);
        if (parseInt(number)) {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter a valid Number",
            text: "Enter a valid Number to continue",
            color: "danger",
            position: "top-center"
          });
          return false;
        }
      } else {
        this.$vs.notify({
          title: "Only 10 digits are allowed to Enter.",
          text: "Enter a valid number",
          color: "danger",
          position: "top-center"
        });
        return false;
      }
    }
  }
};
</script>
